<template>
  <div
    class="option-bg"
    :class="{ 'hover-option': index === hoverIndex, chosen: isChosenOption }"
    :data-index="index"
    @click.stop="clickOption"
    v-if="!isChosenOption && !isHiddenOption"
  >
    <div class="option-name">
      <img v-if="option.avatar" :src="returnSrcFile(option.avatar)" alt="avatar" />
      <div
        v-if="option.color"
        class="option-color"
        :style="`background-color: ${option.color}`"
      ></div>
      <span>{{ option[itemName] }}</span>
    </div>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";

export default {
  name: "OptionSelect",
  mixins: [],
  props: {
    option: {
      type: [String, Object, Array, Number]
    },
    value: {
      type: [String, Object, Array, Number]
    },
    itemName: {
      type: [String, Object, Array, Number]
    },
    index: {
      type: [String, Object, Array, Number]
    },
    hoverIndex: {
      type: [String, Object, Array, Number]
    },
    hiddenItems: {
      type: [String, Object, Array, Number],
      default: () => {
        return [];
      }
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    clickOption() {
      if (this.isChosenOption) {
        this.$emit("deleteChoseOption", this.option);
      } else {
        this.$emit("choseOption", this.option);
      }
    }
  },
  computed: {
    isChosenOption() {
      return (
        this.value.findIndex(
          (val) => val.id === this.option.id || val.user_id === this.option.id
        ) !== -1
      );
    },
    isHiddenOption() {
      return (
        this.hiddenItems.findIndex(
          (val) => val.id === this.option.id || val.user_id === this.option.id
        ) !== -1
      );
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.chosen-option {
  background: #c1c7d0;
}
.option-bg {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px;
  cursor: pointer;

  .option-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    border: 0.75px solid #2281e8;
    border-radius: 2px;
    margin-right: 6px;
  }
  .option-name {
    width: 100%;
    display: flex;
    align-items: center;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .option-color {
      width: 15px;
      height: 15px;
      border-radius: 2px;
      margin-right: 6px;
    }
    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 6px;
    }
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;
  }
}
.chosen {
  background: $color-button-light-1 !important;
  .option-name {
    color: $color-interactive !important;
  }
}
.hover-option,
.option-bg:hover {
  .option-name {
    color: $text-subdued;
  }
}
</style>
